@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  border-color: #aeaeae;
  background-color: #ebebeb;
}

html {
  scroll-behavior: smooth;
}

/* .active{
  color: #a3a3a3;
  box-shadow: ;
} */
.mainLayout {
  max-width: 1170px;
  margin: auto;
}

.Pagination-root {
  direction: ltr !important;
}

[dir='rtl'] .LayoutHeaderContent-left .IconSvg-root {
  transform: rotate(180deg);
}

.inputNumber::-webkit-outer-spin-button,
.inputNumber::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}

.inputNumber {
  -moz-appearance: textfield;
  /* Firefox */
}

[dir='rtl'] .css-3fb2cg-MuiButtonBase-root-MuiListItemButton-root .IconSvg-root {
  transform: rotate(180deg);
}

[dir='rtl'] li .column2 .IconSvg-root {
  transform: rotate(180deg);
}

[dir='rtl'] #view-shopping-cart-button .IconSvg-root {
  transform: rotate(180deg);
}

[dir='rtl'] .CartStartCheckout-checkoutButton .IconSvg-root {
  transform: rotate(180deg);
}

[dir='rtl'] .css-lwsva-4-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root .IconSvg-root,
[dir='rtl'] .css-9tj150-MuiButton-endIcon .IconSvg-root {
  transform: rotate(180deg);
}

[dir='rtl'] .css-z-6rqyd-MuiButton-endIcon .IconSvg-root {
  transform: rotate(180deg);
}

[dir='rtl'] .css-kv9g2o-MuiTypography-root-MuiLink-root .IconSvg-root {
  transform: rotate(180deg);
}

[dir='rtl'] .css-grt56p-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root .IconSvg-root {
  transform: rotate(180deg);
}

[dir='rtl'] .css-1xj2k4r-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root .IconSvg-root {
  transform: rotate(180deg);
}

[dir='rtl'] .MuiButton-iconSizeLarge .IconSvg-root {
  transform: rotate(180deg);
}

[dir='rtl'] .MuiButton-pillSizeMedium .IconSvg-root {
  transform: rotate(180deg);
}

[dir='rtl'] .MuiButton-iconSizeMedium .IconSvg-root {
  transform: rotate(180deg);
}

[dir='rtl'] #next .IconSvg-root {
  transform: rotate(180deg);
}

#r1c {
  direction: ltr;
}

input[name='email'] {
  direction: ltr;
}

input[name='houseNumber'] {
  direction: ltr;
}

input[name='postcode'] {
  direction: ltr;
}

input[name='telephone'] {
  direction: ltr;
}

input[name='password'] {
  direction: ltr;
}

input[name='confirmPassword'] {
  direction: ltr;
}

input[type='password'] {
  direction: ltr;
}

[dir='rtl'] .MuiInputBase-adornedEnd {
  flex-direction: row-reverse;
  padding-right: 14px;
}

.SidebarGallery-scrollerContainer {
  direction: ltr !important;
}

[dir='rtl'] .SidebarGallery-sliderButtons .MuiButtonBase-root .IconSvg-root {
  transform: rotate(180deg);
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
}

.empty-letters {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1.2px #fff;
}

.webkit-text-stroke {
  -webkit-text-stroke: 1px black;
}

.swiper-button-next,
.swiper-button-prev {
  background: #fff;
  box-shadow: 0 0 11px #eeee;
  width: 45px !important;
  height: 45px !important;
  border-radius: 50%;
  padding: 20px;
  font-size: 1rem !important;
  box-sizing: border-box;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  color: black !important;
  font-size: 1rem !important;
}

.swiper-button-disabled {
  opacity: 0 !important;
}

html {
  font-family: var(--font-vazir);
}
.titleLayout {
  background-color: #f3f3f3;
}

.CartItem-item {
  border: #aeaeae 1px solid;
}

.ProductListItem-titleContainer {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  font-size: 14px !important;
  font-weight: 300 !important;
}

.ProductListItem-root {
  background-color: white !important;
  border: 1px solid #eee !important;
  border-radius: 1px !important;
}

.ProductListPrice-root {
  color: #09973e !important;
  font-size: 16px !important;
  font-weight: bold !important;
  text-align: center !important;
  display: flex !important;
  flex-direction: column-reverse;
}

.ProductListPrice-root span {
  font-size: 11px !important;
  color: #999 !important;
}

.ProductListPrice-discountPrice {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
}

@keyframes open {
  from {
    right: -330px;
  }

  to {
    right: 0px;
  }
}

@keyframes close {
  from {
    right: 0px;
  }

  to {
    right: -330px;
  }
}

.animation {
  transition: 0.5s;
  /* animation-name: open;
  animation-duration: 800ms; */
}

.animation-close {
  animation-name: close;
  animation-duration: 800ms;
}

.hidden-scroll-bar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hidden-scroll-bar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

@keyframes open-menu {
  from {
    top: -100px;
    opacity: 0;
  }

  to {
    top: 0px;
    opacity: 1;
  }
}

@keyframes opacity {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.opacity-animation {
  animation-name: open-opacity;
  animation-duration: 200ms;
}

.ltr {
  direction: ltr !important;
}

.rtl {
  direction: rtl !important;
}

.SidebarGallery-centerRight {
  left: max(14px, min((8.67px + 1.67vw), 30px)) !important;
  right: auto !important;
}

.SidebarGallery-centerLeft {
  right: max(14px, min((8.67px + 1.67vw), 30px)) !important;
  left: auto !important;
}

.MuiSlider-thumb {
  -webkit-transform: translate(0%, -50%) !important;
  -moz-transform: translate(0%, -50%) !important;
  -ms-transform: translate(0%, -50%) !important;
  transform: translate(0%, -50%) !important;
}

.FilterRangeType-slider {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.text-overflow-custom {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.contentBody {
  font-size: 14px;
  color: #3a3a3a;
  line-height: 2 !important;
}

.contentBody p {
  font-size: 14px;
  color: #3a3a3a;
  line-height: 2 !important;
}

.contentBody h2 {
  font-size: 21px;
  color: #3a3a3a;
  margin-bottom: 20px;
}

.contentBody h4 {
  font-size: 16px;
  margin-top: 36px;
  margin-bottom: 10px;
  color: #3a3a3a;
}

.heading2 {
  font-size: 20px;
  line-height: 36px;
  font-weight: bold;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.MuiInputLabel-formControl.MuiInputLabel-animated {
  right: 0;
  left: auto;
  transform-origin: top right;
}

.SidebarGallery-sidebarWrapper.zoomed {
  z-index: -1;
}

.om-page-body td {
  border-width: 0.5px;
  text-align: center;
}

.small-scrollbar::-webkit-scrollbar {
  width: 4px;
  max-height: 30px;
}

/* Track */
.small-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.small-scrollbar::-webkit-scrollbar-thumb {
  background: #dbdbdb;
}

/* Handle on hover */
.small-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #c9c9c9;
}

.AddProductsToCartForm {
  position: relative;
}

.configOption-container .MuiButtonBase-root.ActionCard-root.image.variantOutlined {
  box-shadow: none !important;
  padding: 0 !important;
}

.configOption-container .MuiButtonBase-root.ActionCard-root.image.variantOutlined div {
  gap: 0 !important;
  justify-content: center;
}

.configOption-container .ActionCard-title {
  display: none;
}

.configOption-container .ActionCard-image {
  padding-right: 0 !important;
}

.configOption-container .ActionCard-image img {
  width: 35px !important;
  height: 35px !important;
  box-shadow:
    rgba(0, 0, 0, 0.18) 0px 2px 4px 0px,
    rgba(0, 0, 0, 0.15) 0px 4px 8px 0px;
}

.configOption-container .ActionCard-image.selected img {
  box-shadow:
    rgb(70, 186, 113) 0px 0px 0px 2px,
    rgba(70, 186, 113, 0.5) 0px 5px 11px 0px;
}

.categoryDescription h1,
.categoryDescription h2,
.categoryDescription h3,
.categoryDescription h4,
.categoryDescription h5,
.categoryDescription h6 {
  margin: 0 0 20px;
  font-size: 18px;
  line-height: 36px;
  font-weight: 500;
  color: rgb(102, 102, 102);
}

.categoryDescription p {
  margin: 0 0 20px;
  line-height: 28px;
  font-weight: 400;
  color: rgb(102, 102, 102);
  font-size: 14px;
}

.curser {
  cursor: url('/close_cur.png'), auto;
}

.table {
  display: grid;
  grid-auto-flow: column;
}

@media (min-width: 640px) {
  .gallery-transition-bg {
    transition: background-size 0.5s;
  }
}

@media (max-width: 640px) {
  .gallery-transition-bg {
    transition:
      background-size 0.5s,
      background-position 0.5s;
  }
}

@layer utilities {
  @keyframes shake {
    0%,
    100% {
      transform: rotate(0deg);
    }

    25% {
      transform: rotate(10deg);
    }

    50% {
      transform: rotate(-10deg);
    }

    75% {
      transform: rotate(10deg);
    }
  }

  .animate-shake {
    animation: shake 0.5s ease-in-out;
  }
}

@media (min-width: 992px) {
  .p-active-tab {
    background-color: #b20b51 !important;
    color: white !important;
  }
  .p-active-tab > span {
    background-color: #b20b51 !important;
    color: white !important;
    border-width: 1px !important;
    border-color: white !important;
  }
}

@media (max-width: 992px) {
  .p-active-tab {
    background-color: white !important;
    color: #b20b51 !important;
  }
}

.star-icon {
  touch-action: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ordme_btn {
  min-height: 36px;
  min-width: 88px;
  padding: 0 10px;
  background-color: #b20b51;
  color: white;
  font-size: 14px;
  font-weight: 500;
  line-height: 36px;
  text-align: center;
  border-radius: 2px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .26);
  transition: box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  border: none;
  outline: none;
}
.ordme_btn:not([disabled]):focus, .ordme_btn:not([disabled]):hover{
  background-color: #980140;
}
.ordme_btn_secondary{
  background-color: #555;
}
.ordme_btn_secondary:not([disabled]):focus , .ordme_btn_secondary:not([disabled]):hover{
  background-color: #b20b51;
}
.ordme_btn_success{
  background-color: #09973e;
}
.ordme_btn_success:not([disabled]):focus , .ordme_btn_success:not([disabled]):hover{
  background-color: #24AD57;
}
.ordme_btn_ghost{
  color: #555;
  background-color: transparent;
  border: none;
  outline: none;
  box-shadow: none;
}
.ordme_btn_ghost:not([disabled]):focus, .ordme_btn_ghost:not([disabled]):hover {
  color: #555;
  background-color: hsla(0, 0%, 62%, .2);
  box-shadow: none;
}